<template>
	<FloatActions :rightAlign="true" :responsive="{ bp1000: 0.8 }" />
	<div class="content-pad wide">
		<NavSub :routed="true" :items="navItems" />
		<router-view />
	</div>
</template>

<script>
import FloatActions from '@/components/FloatActions'
import NavSub from '@/components/NavSub'

export default {
	name: 'LegalMain',
	components: {
		FloatActions,
		NavSub,
	},
	data() {
		return {
			navItems: [
				{
					display: 'Home',
					to: { name: 'Home' },
				},
				{
					display: 'Terms of Service',
					to: { name: 'LegalTerms' },
				},
				{
					display: 'Privacy Policy',
					to: { name: 'LegalPrivacy' },
				},
				{
					display: 'Copyright Policy',
					to: { name: 'LegalCopyright' },
				},
				{
					display: 'Removal Requests',
					to: { name: 'LegalRemoval' },
				},
			],
		}
	},
}
// TODO: Add a little info on top of each page
// if (page == 'terms') {
// 	data.pageTitle = 'Arthur - Terms & Conditions';
// 	data.description = 'Our Service is used to display and share artworks uploaded by our users, and to allow users to interact with and discover this content across the service.';
// } else if (page == 'copyright') {
// 	data.pageTitle = 'Arthur - Copyright Policy';
// 	data.description = 'It is Arthur’s policy to comply fully with the Digital Millennium Copyright Act and other applicable intellectual property laws.';
// } else if (page == 'privacy') {
// 	data.pageTitle = 'Arthur - Privacy Policy';
// 	data.description = 'This Privacy Policy explains the types of personal information that we receive and collect when you use the services we offer.';
// } else if (page == 'contact') {
// 	data.pageTitle = 'Contact Arthur\'s Copyright Agent';
// 	data.description = 'Please reach out to our copyright agent for notices of copyright infringement and other copyright related requests.';
// }
</script>

<style lang="scss" scoped></style>
